<template>
  <div class="position-relative">
    <PracticeLegend />
    <div v-if="message">
      <AlertSuccess :message="message" />
    </div>
    <Table
        :loading="isLoading"
        :headings="tableHeadings"
        :data="practices"
        @actionView="actionView"
        @actionDelete="actionDelete"
    >
    </Table>
    <v-pagination
        v-model="pageNumber"
        :pages="meta.last_page"
        @update:modelValue="updateHandler"
    >
    </v-pagination>
  </div>

</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Table from "@/components/table/Table.vue";
import { practices, practicesByCategory, practicesByStatus, searchPractices } from "@/api/users.api";
import { Practice } from "@/types/Practice";
import { Paginator } from "@/types/Paginator";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import "@/assets/css/paginator.css";
import PracticesBar from "@/components/actions-bar/PracticesBar.vue";
import Modal from "@/components/modals/Modal.vue";
import PracticeForm from "@/components/forms/practices/PracticeForm.vue";
import { spinnerIsLoading } from "@/mixins/spinner.mixin";
import { categories, createPractice} from "@/api/categories.api";
import AlertSuccess from "@/components/alerts/AlertSuccess.vue";
import {forceDeletePractice, trashed} from "@/api/practices.api";
import PracticeFilters from "@/components/actions-bar/actions/PracticeFilters.vue";
import { Category } from "@/types/Category";
import { FilterType, StatusType } from "@/types/PracticeFilterEnum";
import {sweetAlertCallback, sweetAlertSuccess} from "@/utilities/sweetalert";
import { mapGetters } from 'vuex'
import PracticeLegend from "@/components/statics/PracticeLegend.vue";

export default defineComponent({
  name: "TrashedPractices",
  mixins: [spinnerIsLoading],
  components: {
    PracticeLegend,
    PracticeFilters,
    AlertSuccess,
    PracticeForm,
    Modal,
    PracticesBar,
    Table,
    VPagination,
  },
  data() {
    return {
      componentKey: 0 as number,
      pageNumber: 1 as number,
      userId: this.$store.getters.user.id as number,
      practices: [] as Practice[],
      meta: {} as Paginator,
      message: '' as string,
      categories: [] as Category[],
      filter: -1 as FilterType,
      filterId: -1 as any, // TODO: can be number or string: status => 'Completed' || Category = 0-1-2
      searchName: '' as string,
      tableHeadings: {
        id: '#',
        label: 'Titolo',
        status: 'Stato',
        category_name: 'Categoria',
        created_at: 'Creata il'
      }
    }
  },
  methods: {
    async search() {
      if (!this.searchName) return;

      this.startLoading();
      const response: any = await searchPractices(this.searchName, this.userId);
      this.stopLoading();
      this.practices = response.data;
    },
    filterHandler(data: { filter: number, id: number|string }) {
      this.filter = FilterType.None;
      this.message = '';

      const { filter, id } = data;

      this.filterId = id;
      this.filter = filter;

      this.filterSwitch(filter)
    },
    filterSwitch(filter: FilterType, page = 1) {
      if (this.pageNumber !== page) this.pageNumber = 1;

      switch (filter) {
        case FilterType.Category:
          return this.paginateByCategory(this.filterId, page);
        case FilterType.Status:
          return this.paginateByStatus(this.filterId, page);
        case FilterType.Year:
          return console.log('Cerca by anno');
        case FilterType.None:
          return this.getPractices(this.user.id, page);
        default: return;
      }
    },
    resetFilter(): void {
      this.filter = FilterType.None;
    },
    async paginateByStatus(id: StatusType, pageNumber = 1) {
      this.startLoading();

      const response: any = await practicesByStatus(id, pageNumber, this.userId);

      if (!response.data) {
        return this.message = 'Si è verificato un errore';
      }

      if (!response.data.length) {
        return this.message = 'Non sono presenti pratiche con questo stato';
      }

      this.practices = response.data;
      this.meta = response.meta;

      this.stopLoading();
    },
    async paginateByCategory(id: number, pageNumber = 1) {
      this.startLoading();

      const response: any = await practicesByCategory(id, pageNumber, this.userId);

      if (!response.data) {
        return this.message = 'Si è verificato un errore';
      }

      if (!response.data.length) {
        return this.message = 'Non sono presenti pratiche per questa categoria';
      }

      this.practices = response.data;
      this.meta = response.meta;

      this.stopLoading();
    },
    async getPractices(userId: number, page: number) {
      this.isLoading = true;

      const response: any = await trashed(page);

      this.practices = response.data;
      this.meta = response.meta;

      this.isLoading = false;
    },
    async getCategories() {
      const response = await categories();
      this.categories = response.data;
    },
    updateHandler(page: number) {
      this.pageNumber = page;

      this.filterSwitch(this.filter, page);
    },
    async createPractice(data: any) {
      const { categoryId, label } = data;
      this.isLoading = true;

      const response = await createPractice(categoryId, label);
      const practice: Practice = response.data;
      this.isLoading = false;

      if (practice) {
        this.practices.splice(0, 0, practice);
        // For pagination
        this.practices.length >= 10 && this.practices.pop();
        this.message = 'Hai creato una nuova pratica con successo!';
        this.resetMessage();

      }

      (document.querySelector('.close-modal') as HTMLElement)?.click();
      practice && await this.$router.push({ name: 'Documents', params: { id: practice.id }});
    },
    actionView(id: number) {
      this.$router.push({ name: 'Documents', params: { id }});
    },
    async actionDelete(data: any) {
      const { id } = data;

      sweetAlertCallback('Sei sicuro di voler eliminare definitivamente la pratica?')
          .then(async (result) => {
            if (!result.isConfirmed) return;

            const response = await forceDeletePractice(id);

            if (!response) return;

            const index = this.practices.findIndex((practice: Practice) => practice.id === id);

            if (index <= -1) return;

            this.practices.splice(index, 1);

            return sweetAlertSuccess('success','Pratica eliminata con successo');
          })
    },
    resetMessage() {
      setInterval(() => {
        this.message = '';
      }, 10000);
    },
    isAdministrator() {
      return !!this.$store.getters.user.is_admin;
    }
  },
  computed: {
    ...mapGetters([
      'user',
    ])
  },
  mounted() {
    if (this.user.is_admin) {
      // @ts-ignore
      this.tableHeadings.name = 'Di'
    }

    this.getPractices(this.user.id, this.pageNumber);
    this.getCategories();
  }
})
</script>

<style scoped>
.form-control {
  border-radius: 22px;
}

label {
  font-weight: 600;
  margin-left: 0.8rem;
}


</style>